import React, { useState, useEffect , useContext } from 'react';
import iconSearch from "../../assets/images/search-solid.svg";
import { explorerContext } from '../../context/explorerContext';

export default function AutoCompelete(props) {
  const context = useContext(explorerContext);

  const [ activeSuggestion , setActiveSuggestion ] = useState(0);
  const [ filteredSuggestions , setFilteredSuggestions ] = useState([]);
  const [ showSuggestions , setShowSuggestions ] = useState(false);
  const [ userInput , setUserInput ] = useState("");
  const [ searchOptions , setSearchOptions ] = useState([]);

  useEffect(() => {
    context.getAllMarketsAction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context.allLocation && context.allLocation.communes && context.allSearch) {
      var options = context.allSearch;
      options.push(context.allMarkets.map(m => ({label: m.label , slug: m.slug , searchType: "companies" })))
      options.push(context.allLocation.communes);
      options.push(context.allLocation.intercommunautes);
      setSearchOptions(options.flat());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.allLocation, context.allSearch]);

  useEffect(() => {
    if (context.activeLocation && context.activeLocation.location !== userInput) {
      setUserInput("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.activeLocation])


  const onChangeInput = (e) => {
    const userInput = e.target.value;
    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = searchOptions.filter( suggestion => suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1 );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    setActiveSuggestion(0);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(e.currentTarget.value);
  };

  const onClickGetSearchFromApi = (suggestion) => {
    // Update the user input and reset the rest of the state
    let reqBody = context.activeLocation;
    reqBody.slug = suggestion.slug;

    if (suggestion.searchType === "ecosysteme") {
      context.setSelectedEco(context.ecoSystemes.nodes.filter(item => item.slug === suggestion.slug)[0]);
      context.getMarketAction(reqBody);
      context.setActiveViz("vizMarket");
    }

    if (suggestion.searchType === "companies") {
      context.getCompaniesAction(reqBody);
      context.setActiveViz("vizCompanies");
    }

    if (suggestion.searchType === "communes") {
      props.handleChangeCommune(suggestion);
    }

    if (suggestion.searchType === "intercommunautes") {
      props.handleChangeIntercommunaute(suggestion);
    }

    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(suggestion.label);
  };

  const onKeyDown = (e) => {
    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(filteredSuggestions[activeSuggestion].slug);
      onClickGetSearchFromApi(filteredSuggestions[activeSuggestion]);
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1 );
    }
  };

  const getSuggestions = () => {
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        return (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;
              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={index}
                  onClick={() => onClickGetSearchFromApi(suggestion)}
                >
                  {suggestion.label}
                </li>
              );
            })}
          </ul>);
        } else {
          return (
            <p className="no-suggestions">
              <em>Aucune suggestion ne correspond à votre recherche</em>
            </p>
          );
        }
    }
  }

  return (
    <div className="search-container">
      <div className="icon-search">
        <img className="icon-search" src={iconSearch} alt="search"/>
      </div>
      <input
        className="search"
        type="text"
        onChange={(e) => onChangeInput(e)}
        onKeyDown={onKeyDown}
        value={userInput}
      />
      {getSuggestions()}
    </div>
  );
}