import React, { useEffect, useState, useContext } from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';

import { explorerContext } from '../../context/explorerContext';
import { hexToRGBa } from '../../utils/utils';
import NavLinkViz from '../DataViz/NavLinkViz';
import NoData from '../Partials/NoData';
import Loader from '../Partials/Loader';

export default function VizMarket(props) {
  const context = useContext(explorerContext);
  const [ marginTopLink , setMarginTopLink ] = useState();
  const [ displayLinks , setDisplayLinks ] = useState(false);

  var simulationEco;
  var simulationMarket;
  

  useEffect(() => {
    context.setDisplayLoader(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    d3.selectAll("#svg-links-eco > *").remove();
    d3.selectAll("#graph-market > *").remove();
    setMarginTopLink(document.querySelector("#graph-market").clientHeight - 154);

    if (!context.vizIsPending && context.markets.length > 0 ) {
      drawVizMarket();
    } else {
      context.setDisplayNoData(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.vizIsPending , context.markets, context.selectedYear, context.activeLocation, context.yearEvolution]);

  const drawVizMarket = () => {
    context.setDisplayLoader(true);
    context.setDisplayNoData(false);

    let div = d3.select("#graph-market");
    let svg = d3.select("#svg-links-eco")

    let decalageX = 0;
    let decalageY = 150;
    let minNodeSizeViz = 10;
    let maxNodeSizeViz = 100;
    let width = document.querySelector("#graph-market").clientWidth - decalageX;
    let height = document.querySelector("#graph-market").clientHeight - decalageY;
    let mainCircleSize = width /2.3;

    if (mainCircleSize > 550) {
      mainCircleSize = 550
    }

    let dateId = Date.now().toString();

    setMarginTopLink(mainCircleSize + ((height - mainCircleSize) /2 ) + 80 );

    var markets = [];

    context.markets.map(item => {

      let found = item.years.filter(item => item.year === context.selectedYear);
      if (found.length === 0) return null;

      let yearValue = item.years.filter(item => item.year === context.selectedYear)[0].size;
      if (context.evolutionIsActive === true && yearValue > 0 ) {
        let yearEvoValue = item.years.find(item => item.year === context.yearEvolution);
        if (yearEvoValue && yearEvoValue.size) {
          let evolutionValue = yearValue - yearEvoValue.size;
          if (evolutionValue === 0) {
            item.evolColor = '#787878';
          } if (evolutionValue > 0) {
            item.evolColor = '#35D980';
          } else {
            item.evolColor = '#FF4959';
          }
          item.size = evolutionValue;
          return markets.push(item);
        }
      } else {
        item.size = yearValue;
        if (yearValue > 0 ) {
          return markets.push(item);
        }
      }
      return item;
    });

    // SET NODES //

    if (markets.length === 0 ) {
      context.setDisplayLoader(false);
      context.setDisplayNoData(true);
      setDisplayLinks(true)
      return null
    }

    let maxNode = _.maxBy(markets, 'size').size;
    let minNode = _.minBy(markets, 'size').size;

    var maxNodeSize = maxNode

    if (maxNode < Math.abs(minNode)) {
      maxNodeSize = Math.abs(minNode)
    }

    var setNodeScale = d3.scaleLinear()
    .domain([ 0 , maxNodeSize])
    .range([minNodeSizeViz , maxNodeSizeViz]);

    _.each(markets, function (node) {
      node.sizeViz = setNodeScale(Math.abs(node.size))
    });

    let ecoArray = [];

    markets.map((d) => {
      d.ecosystemes = d.ecosystemes.filter(eco => eco !== context.selectedEco.slug);
      if (d.ecosystemes.length > 0 && d.ecosystemes[0] !== context.selectedEco.slug) {
        return ecoArray.push(d.ecosystemes[0]);
      }
      return d;
    })

    ecoArray = _.uniq(ecoArray);

    //SET ECO SYSTEME AREA//
    let ecoNodes = []

    ecoArray.map((d) => {
      let index;
      let found = context.ecoSystemes.nodes.find( item => {
        index = context.ecoSystemes.nodes.indexOf(item);
        return item.slug === d;
      })
      if (found) {
        return ecoNodes.push(context.ecoSystemes.nodes[index]);
      }
      return d;
    })

    ecoNodes.map((d, index) => {
      let size =  0;
      markets.filter(item => { return item.ecosystemes[0] === d.slug}).map(item => { return size = size + item.sizeViz});
      if (size < 30) size = 30;
      if (size > 100) size = 120;
      d.sizeViz = size * 1.1

      return d
    })

    // SET CENTER ZONE AREA POSITION X Y//
    var center = [];
    var centerButton = [];
    var targetButton = []

    let angle = 360 / (ecoArray.length + 1 );

    function calcTargetButton(obj) {
      var radius = (mainCircleSize / 2.35 ) + (obj.d.sizeViz/2) + 4 ;
      var x = radius * Math.sin(-Math.PI * 2 * obj.angle / 360);
      var y = radius * Math.cos(-Math.PI * 2 * obj.angle / 360);
      return {
        x: (width/2 + x ) ,
        y: (height/2 + y )
      }
    }

    function calcCenterArea(angle) {
      var radius = mainCircleSize / 2.35  ;
      var x = radius * Math.sin(-Math.PI * 2 * angle / 360);
      var y = radius * Math.cos(-Math.PI * 2 * angle / 360);
      return {
        x: (width/2 + x -  4) ,
        y: (height/2 + y - 4)
      }
    }


    function calcCenterButton(angle) {
      var radius = mainCircleSize / 1.45 ;
      var x = radius * Math.sin(-Math.PI * 2 * angle / 360);
      var y = radius * Math.cos(-Math.PI * 2 * angle / 360);
      return { 
        x: (width/2 + x) ,
        y: (height/2 + y),
      }
    }


    function setCenterArea() {
      ecoNodes.map((d, index) => {
        center.push(calcCenterArea((angle * index) + angle));
        centerButton.push(calcCenterButton((angle * index) + angle));
        var obj = {
          angle: (angle * index) + angle,
          d: d
        }
        targetButton.push(calcTargetButton(obj));
        return d;
      })
    }

    setCenterArea()

    ecoNodes.map((d, index) => {
      d.x = center[index].x ;
      d.y = center[index].y ;
      d.xButton = centerButton[index].x ;
      d.yButton = centerButton[index].y ;
      d.xButton2 = targetButton[index].x ;
      d.yButton2= targetButton[index].y;
      return d
    })

    function isLabel(d) {
      const market = d.years.find((y) => y.year === context.selectedYear);
      if ((market && market.companies < 5) || market.concentration) {
        return false;
      }
      return true;
    }

    markets.map((d) => {
      var eco = ecoNodes.filter(eco => eco.slug === d.ecosystemes[0] )[0]
      if (eco && eco.slug === d.ecosystemes[0]) {
        d.x = eco.x;
        d.y = eco.y;
      } else {
        d.x = width/2;
        d.y = height/2;
      }
      return d;
    })

    markets.sort(function(a,b){ return b.sizeViz - a.sizeViz; });

    if(simulationEco){
      simulationEco.stop()
    }

    if(simulationMarket){
      simulationMarket.stop()
    }

    simulationEco = d3.forceSimulation()
      .force('forceX', d3.forceX(width/2))
      .force('forceY', d3.forceY(height/2))

    simulationMarket = d3.forceSimulation()
      .force('forceX', d3.forceX(width/2))
      .force('forceY', d3.forceY(height/2));

    div
      .attr("width", width + "px")
      .attr("height", height + "px");

    var lines = svg.selectAll("line")
      .data(ecoNodes)
      .enter()
      .append("line")
      .attr("class", "line")
      .style("stroke", function(d) { return hexToRGBa(d.color , 0.4) })
      .style("transform", function(d) { return "translate("+ 0 +"px," + 31 + "px)" })
      .attr("x1", function(d) { return d.xButton })
      .attr("y1", function(d) { return d.yButton })
      .attr("x2", function(d) { return d.xButton })
      .attr("y2", function(d) {  return d.yButton })
      .style("stroke-width", "4px");

    div.selectAll(".background-viz-market")
      .data([0])
      .enter()
      .append("div")
      .attr("class", "background-viz-market")
      .style("position", "absolute")
      .style("background-color", context.selectedEco.color)
      .style("border-radius", mainCircleSize + "px" )
      .style("width", 0 + "px" )
      .style("height", 0 + "px" )
      .style("transform", function(d) { return "translate("+ (width/2) +"px," + (height/2) + "px)" })
      .transition()
      .duration(500)
        .style("width", mainCircleSize + "px" )
        .style("height", mainCircleSize + "px" )
        .style("transform", function(d) { return "translate("+ (width/2 - (mainCircleSize/2)) +"px," + (height/2 - (mainCircleSize/2)) + "px)" })
        .style("opacity", 0.2);

    div.selectAll(".eco-area")
      .remove()
      .data(ecoNodes)
      .enter()
      .append("div")
      .attr("class", "eco-area")
      .attr("id", function (d) { return dateId + d.slug})
      .style("position", "absolute")
      //.style("background-color", function (d) { return d.color })
      .style("background-color", "transparent")
      .style("border", function(d){ return "6px solid " + hexToRGBa(d.color, 0.4)})
      .style("width", function(d){  return 0 + "px"})
      .style("height", function(d){  return 0 + "px" })
      .style("transform", function(d) {
        return "translate("+ (width/2) +"px," + (height/2) + "px)" })
      .style("border-radius", function(d){  return d.sizeViz + "px" })
      .style("opacity", 1);

    div.selectAll(".eco-button")
      .data(ecoNodes)
      .enter()
      .append("div")
      .attr("class", "eco-button")
      .attr("id", function (d) { return dateId + d.sizeViz})
      .style("position", "absolute")
      .style("background-color", function (d) { return d.color })
      .style("background-image", function (d) {
        return "url(./images/icons/" + d.slug + ".png)"
      })
      .style("width", function(d){  return 0 + "px"})
      .style("height", function(d){  return 0 + "px" })
      .style("transform", function(d) {
        return "translate("+ (width/2) +"px," + (height/2) + "px)" })
      .style("border-radius", 50 + "px" )
      .style("opacity", 1 )
      .on("click", function (d) {
        d3.selectAll("#graph-market > *").remove();
        simulationMarket.stop()
        simulationEco.stop()
        props.displayVizMarket(d);
      })
      .append("p")
      .attr("class", "eco-button-label")
      .style("position", "absolute")
      .style("width", "150px")
      .style("text-align", "center")
      .style("color", function(d) { return d.color })
      .text(function (d) {
        return d.label;
      })

    div.selectAll(".eco-button")
      .append("div")
      .attr("class", "eco-button-hover")
      .style("position", "absolute")
      .style("height", "50px")
      .style("width", "50px")
      .style("z-index", "-1")
      .style("border", function(d){ return "6px solid " + hexToRGBa(d.color, 0.1)})
      .style("border-radius", "52px")
      .on("mouseover", function(d) {
        d3.select(this)
          .transition()
          .duration(300)
          .style("border", function(d){ return "6px solid " + hexToRGBa(d.color, 0.5)})

        d3.selectAll('.eco-value')
          .transition()
          .duration(300)
          .style("opacity", function(data) {
            if (data.slug === d.slug) {
              return 1;
            } else {
              return 0;
            }
          });
      })
      .on("mouseout", function(d) {
        d3.select(this)
          .transition()
          .duration(300)
          .style("border", function(d){ return "6px solid " + hexToRGBa(d.color, 0.1)})
        
        d3.selectAll('.eco-value')
          .transition()
          .duration(300)
          .style("opacity", 0 );
      });

    div.selectAll(".market-label")
      .remove()
      .data(markets)
      .enter()
      .append("p")
      .attr("class", "market-label")
      .attr("id", function(d) { return d.slug + dateId })
      .style('position', 'absolute')
      .style("height", function(d) { return d.sizeVis })
      .style("transform", function(d) { return "translate("+ (width/2) +"px," + (height/2) + "px)" })
      .style("z-index", 90)
      .style("color", hexToRGBa("#58585a"))
      .style("opacity", function(d) {
        if(d.sizeViz < 30) {
          return 0;
        } else {
          return 1;
        }
      })
      .text(function (d) {
        return d.label;
      });

    div.selectAll(".eco-label")
      .data(markets)
      .enter()
      .append("p")
      .attr("class", "eco-label")
      .attr("id", function (d) { return dateId + d.slug + dateId})
      .style("position", "absolute")
      .style('pointer-events', "none")
      .style("opacity", 0)
      .style("transform", function (d) {
        return  "translate(" + (d.x + this.offsetWidth / 2) + "px," + (d.y - this.offsetHeight / 2) + "px)"
      })
      .style("margin-top", function(d) {
        return 40 + "px"
      })
      .style("color", function (d) {
        let color = context.allEcoSystemes.filter(item => item.slug === d.ecosystemes[0])
        if (color.length > 0) {
          return color[0].color;
        } else {
          return context.selectedEco.color
        }
      })
      .text(function (d) {
        let text = context.allEcoSystemes.filter(item => item.slug === d.ecosystemes[0]);
        if (text.length > 0) {
          return text[0].label;
        } else {
          return context.selectedEco.label
        }
      });

    var ecoValue = div.selectAll(".eco-value")
      .data(ecoNodes)
      .enter()
      .append("p")
      .attr("class", "eco-value")
      .style("position", "absolute")
      .style('pointer-events', "none")
      .style("opacity", 0)
      .style("transform", function(d) {
        return "translate("+ (width/2) +"px," + (height/2) + "px)" })
      
    ecoValue.append("span")
      .attr("class", "bold")
      .text(function (d) {
        let result = 0 ;
        markets.forEach(item => {
          if (item.ecosystemes[0] === d.slug ) {
            result += item.size
          }
        });
        return result
      });

    ecoValue.append("span").text(" postes salariés privés");
    

    div.selectAll(".circle")
      .remove()
      .data(markets)
      .enter()
      .append("div")
      .attr("class", "circle")
      .attr("id", function(d) { return dateId + d.slug })
      .style("width", function(d){  return d.sizeViz + "px"})
      .style("height", function(d){  return d.sizeViz + "px" })
      .style("position", "absolute")
      .style("background-color", function(d) {
        if (context.evolutionIsActive === true) {
          return d.evolColor
        } else {
          return context.selectedEco.color
        }
      })
      .style("border-radius", function (d) {
      let value = d.sizeViz;
        return value + "px";
      })
      .on("click", function (d) {
        simulationMarket.stop()
        if(context.evolutionIsActive === false) {
          props.displayVizCompanies(d);
          d3.selectAll("#graph-market > *").remove();
        }
      })
      .call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended))
      .on("mouseover", function (d) {
        simulationMarket.stop()

        d3.select("#"+ d.slug )
          .style("color", context.selectedEco.color)
          .style("z-index", 90)

        d3.select(this)
          .style("z-index", 80)

        d3.selectAll('.circle')
          .transition()
          .duration(300)
          .style("width", function(item){
            if (isLabel(item) && item.sizeViz < 50 && dateId +item.slug === dateId + d.slug) {
              return 50 + "px"
            } else {
              return item.sizeViz + "px"
            }
          })
          .style("height", function(item){
            if (isLabel(item) && item.sizeViz < 50 && dateId + item.slug === dateId + d.slug) {
              return 50 + "px"
            } else {
              return item.sizeViz + "px"
            }
          })
          .style("transform", function(item){
            if (isLabel(item) && item.sizeViz < 50 && dateId + item.slug === dateId + d.slug) {
              return "translate("+ (d.x - 20) + "px," + (d.y - 20) + "px)";
            } else {
              return "translate("+ (item.x + 5 - (item.sizeViz /2)) + "px," + (item.y + 5 - (item.sizeViz /2)) + "px)";
            }
          })
          .style("border-radius", function(item){
            if (isLabel(item) && item.sizeViz < 50 && item.slug === d.slug) {
              return 50 + "px"
            } else {
              return item.sizeViz + "px"
            }
          })
          .style("background-color", function (data) {
            if (context.evolutionIsActive === true) {
              if (data.slug !== d.slug) {
                return hexToRGBa(data.evolColor, 0.2);
              } else {
                return hexToRGBa(data.evolColor, 0.7);
              }
            } else {
              if (data.slug !== d.slug) {
                return hexToRGBa(context.selectedEco.color, 0.2);
              } else {
                return hexToRGBa(context.selectedEco.color, 0.7);
              }
            }
          });

        d3.selectAll('.eco-label')
          .transition()
          .duration(300)
          .style("opacity", function(data) {
            if (data.slug === d.slug) {
              return 1;
            } else {
              return 0;
            }
          });

        d3.selectAll('.eco-button')
          .transition()
          .duration(300)
          .style("opacity", function (data) {
            if (data.slug !== d.ecosystemes[0]) {
              return 0.1;
            } else {
              return 1;
            }
          });

        d3.selectAll('.eco-area')
          .transition()
          .duration(300)
          .style("opacity", function (data) {
            if (data.slug !== d.ecosystemes[0]) {
              return 0.1;
            } else {
              return 1;
            }
          });

        d3.selectAll('.line')
          .transition()
          .duration(300)
          .style("opacity", function (data) {
            if (data.slug !== d.ecosystemes[0]) {
              return 0;
            } else {
              return 1;
            }
          });

        d3.selectAll('.market-label')
          .transition()
          .duration(300)
          .style("z-index", function (data) {
            if (data.slug === d.slug) {
              return 90;
            }
          })
          .style("margin-top", function (data) {
            if (data.slug === d.slug) {
              return -this.offsetHeight - 10 + "px";
            }
          })
          .style("background-color", function (data) {
            if (data.slug === d.slug) {
              return "rgba(255,255,255,0.9)";
            }
          })
          .style("box-shadow", function (data) {
            if (data.slug === d.slug) {
              return "2px 4px 5px rgba(0,0,0,0.2)";
            }
          })
          .style("opacity", function (data) {
            if (data.slug !== d.slug) {
              return 0;
            } else {
              return 1;
            }
          });

        d3.select(this)
          .select('.circle-hover-text')
          .style('opacity', (d) => {
            return isLabel(d) ? 1: 0
          });


        d3.select(this)
          .select('.circle-hover')
          .style('display', 'flex');

      })
      .on("mouseout", function (d) {
        simulationMarket.restart()

        d3.select("#"+d.slug )
          .style("color", hexToRGBa("#58585a"))
          .style("z-index", 4);

        d3.select(this)
          .style("transform", function(d) {
            return "translate("+ (d.x - (d.sizeViz /2)) + "px," + (d.y - (d.sizeViz /2)) + "px)";
          })
          .style("z-index", 3);

        d3.selectAll('.circle')
          .transition()
          .duration(300)
          .style("width", function(d){  return d.sizeViz + "px"})
          .style("height", function(d){  return d.sizeViz + "px" })
          .style("transform", function(d) {
            return "translate("+ (d.x + 5 - (d.sizeViz /2)) + "px," + (d.y + 5 - (d.sizeViz /2)) + "px)";
          })
          .style("border-radius", function(d){  return d.sizeViz + "px" })
          .style("background-color", function (data) {
            if (context.evolutionIsActive === true) {
                return data.evolColor;
            } else {
              return hexToRGBa(context.selectedEco.color);
            }
          });

        d3.selectAll('.eco-label')
          .transition()
          .duration(300)
          .style("opacity", 0)

        d3.selectAll('.eco-area')
          .transition()
          .duration(300)
          .style("opacity", 1 );

        d3.selectAll('.line')
          .transition()
          .duration(300)
          .style("opacity", "1");

        d3.selectAll('.eco-button')
          .transition()
          .duration(300)
          .style("opacity", "1");

        d3.select(this)
          .select('.circle-hover')
          .style('display', 'none');

        d3.selectAll('.market-label')
          .transition()
          .duration(300)
          .style("z-index", 90)
          .style("background-color", "transparent")
          .style("box-shadow", "none")
          .style("margin-top", function (data) {
            if (data.slug === d.slug) {
              return 0 + "px";
            }
          })
          .style("opacity", function(d) {
            if(d.sizeViz < 30) {
              return 0;
            } else {
              return 1;
            }
          });
      })
      .append("div")
      .attr("class", "circle-hover")
      .style("height", function (d) {
        if (d.sizeViz < 50 ) {
          return 50 + "px"
        } else {
          return d.sizeViz + "px"
        }
      })
      .style("width", function (d) {
        if (d.sizeViz < 50 ) {
          return 50 + "px"
        } else {
          return d.sizeViz + "px"
        }
      })
      .style("border-radius", function (d) {
        if (d.sizeViz < 50 ) {
          return 50 + "px"
        } else {
          return d.sizeViz + "px"
        }
      })
      .append("p")
      .attr("class", "circle-hover-text")
      .style('min-width', '200px')
      .style('text-align', 'center')
      .html(function (d) {
      let value = d.size
      let fontSize = 22
      if (d.sizeViz < 50) fontSize = 16
        if (context.evolutionIsActive === true && d.size > 0 ) {
          return  "<span style='text-align: center; font-size:" + fontSize + "px; line-height:" + (d.sizeViz / 4) + "px; font-family: roboto-black;'>+" + value + "</span><br/>postes salariés privés";
        } else {
          return  "<span style='text-align: center; font-size:" + fontSize + "px; line-height:" + (d.sizeViz / 4) + "px; font-family: roboto-black;'>" + value + "</span><br/>postes salariés privés";
        }
      });

    let tickedEco = function() {
      lines
        .transition()
        .delay(1500)
        .duration(300)
        .ease(d3.easeLinear)
        .attr("x2", function(d) { return d.xButton2 })
        .attr("y2", function(d) {  return d.yButton2 })


      div.selectAll(".eco-area")
        .transition()
        .duration(300)
        .ease(d3.easeLinear)
        .style("width", function(d){  return d.sizeViz + "px"})
        .style("height", function(d){  return d.sizeViz + "px" })
        .style("transform", function(d) { return "translate("+ (d.x - (d.sizeViz /2)) + "px," + (d.y - (d.sizeViz /2)) + "px)" });

    }

    let tickedMarket = function() {

      div.selectAll(".eco-button")
      .style("width",  50 + "px" )
      .style("height",  50 + "px" )
      .style("transform", function (d) {
        return  "translate(" + (d.xButton - this.offsetWidth / 2) + "px," + (d.yButton - this.offsetHeight / 2) + "px)"
      });

      div.selectAll(".eco-button-hover")
        .style("transform", function (d) {
          return  "translate(" + -6 + "px," + -6 + "px)"
        });

      div.selectAll(".eco-button-label")
          .style("transform", function (d) {
            return  "translate(" + -50 + "px," + 40 + "px)"
          });

      div.selectAll(".eco-value")
        .style("transform", function (d) {
          return  "translate(" + (d.xButton - this.offsetWidth / 2) + "px," + (d.yButton - 60) + "px)"
        });

      div.selectAll(".eco-label")
        .style("transform", function (d) {
          return  "translate(" + (d.x + 5 - this.offsetWidth / 2) + "px," + (d.y + 5 - this.offsetHeight / 2) + "px)"
        });

      div.selectAll(".circle")
        .style("width", function(d){  return d.sizeViz + "px"})
        .style("height", function(d){  return d.sizeViz + "px" })
        .style("transform", function(d) {
            return "translate("+ (d.x + 5 -(d.sizeViz /2)) + "px," + (d.y + 5 - (d.sizeViz /2)) + "px)";
        });

      div.selectAll(".market-label")
        .style("transform", function (d) {
          return  "translate(" + (d.x + 5 - this.offsetWidth / 2) + "px," + (d.y - this.offsetHeight / 3) + "px)"
        });
    }

    context.setDisplayLoader(false);
    setDisplayLinks(true)

    simulationEco
      .nodes(ecoNodes)
      .force('forceX', d3.forceX().strength(0.5).x(function(d) { return d.x }))
      .force('forceY', d3.forceY().strength(0.5).y(function(d) { return d.y }))
      .on("tick", tickedEco);

    simulationMarket
      .nodes(markets)
      .force('forceX', d3.forceX().strength(function(d) {
        if(d.ecosystemes.length === 0) {
          var value = d.sizeViz/maxNodeSizeViz
          return value * 0.5;
        } else {
          return 0.5;
        }
      }).x(function(d) { return d.x }))
      .force('forceY', d3.forceY().strength(function(d) {
        if(d.ecosystemes.length === 0) {
          var value = d.sizeViz/maxNodeSizeViz
          return value * 0.5;
        } else {
          return 0.5;
        }
      }).y(function(d) { return d.y }))
      .force("collide", d3.forceCollide(function(d) {
        if(d.ecosystemes.length === 0) {
          //return d.sizeViz * 0.9;
          return d.sizeViz / 1.35
        } else {
          //return d.sizeViz * 0.6;
          return d.sizeViz < 80 ? d.sizeViz / 1.55 : d.sizeViz / 2
        }
      }).strength(0.6))
      .on("tick", tickedMarket)

    function dragstarted(d) {
      if (!d3.event.active) simulationMarket.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragended(d) {
      if (!d3.event.active) simulationMarket.alphaTarget(0).restart();
      d.fx = null;
      d.fy = null;
    }
  }

  d3.select(window).on('resize', drawOnResize);

  function drawOnResize() {
    if(context.markets && document.querySelector("#graph-companies")) {
      drawVizMarket()
    }
  }

  return (
    <>
      <NoData />
      <Loader />
      <svg id="svg-links-eco" className="dataviz-container"></svg>
      <div id="graph-market" style={{ marginTop: 30}} className="dataviz-container"></div>
      {displayLinks && 
        <NavLinkViz
          marginTop={marginTopLink}
          selectedEco={context.selectedEco}
          selectedYear={context.selectedYear}
          displayMarketLink={false}
          hexToRGBa={hexToRGBa}
          displayVizEco={props.displayVizEco}
          />
      }
    </>
  )
}
