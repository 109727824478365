import React , { useContext , useEffect }from 'react';

import audiarLogo from '../../assets/images/logo.png';

import { explorerContext } from '../../context/explorerContext.js';
import { authContext } from '../../context/authContext';

import Header from '../Partials/Header';
import VizMarket from '../DataViz/VizMarket';
import VizEco from '../DataViz/VizEco';
import VizCompanies from '../DataViz/VizCompanies';
import SideBar from '../Partials/SideBar';
import CompanyModal from '../DataViz/CompanyModal';
import Loader from '../Partials/Loader';
import { useLocation } from 'react-router-dom';
import { handleChangeQuery, parseQuery } from '../../utils/utils';

function Explorer() {
  //VARIABLES///
  const { search } = useLocation();
  const query = parseQuery(search);

  const context = useContext(explorerContext);
  const auth = useContext(authContext);
  //EFFECT//

  useEffect(() => {
    const token = query && query.token;
    if(token) {
      localStorage.setItem('token',`JWT ${token}`);
    }
    context.getAllSearchAction();
    context.getAllLocationAction();
    auth.setActivePage("explorer")
    return function cleanup() {
      context.setEcoSystemes([]);
      context.setAllEcoSystemes([]);
      context.setMarkets([]);
      context.setSelectedCompany({});
      context.setActiveLocation({ "searchType": "communes", "location": "all" });
      context.setActiveViz("vizEco");
      context.setEvolutionIsActive(false);
      context.setDisplayLoader(true);
      context.setDisplayNoData(false);
      context.setSelectedYear("2015");
      context.setCompanyIsOpen(false);
      context.setYearEvolution("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  console.log(search, context);
  useEffect(() => {
    if (query) {
      const location = {
        zones: query.zones || "",
        searchType: query.searchType || "communes",
        location: query.location || "all",
      }
      if (query.year) {
        context.setSelectedYear(query.year);
      }
      if (query.evolution) {
        context.setEvolutionIsActive(true);
        context.setYearEvolution(query.evolution);
      }
      if (query.activeViz) {
        context.setActiveViz(query.activeViz);
      }
      if (query.sectors) {
        location.sectors = JSON.parse(query.sectors);
      }
      if (query.types) {
        location.types = JSON.parse(query.types);
      }
      if (query.ess) {
        location.ess = JSON.parse(query.ess);
      }
      if (query.slug) {
        location.slug = query.slug;
      }
      context.setActiveLocation(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const { allEcoSystemes } = context;

  useEffect(() => {
    if (context.allEcoSystemes && query && query.eco) {
      const found = context.allEcoSystemes.find((e) => e.slug === query.eco);
      if (found && found.slug && context.activeViz === "vizMarket") {
        context.setSelectedEco(found);
        context.setDisplayLoader(true);
        let reqBody = {...context.activeLocation };
        reqBody.slug = found.slug;
        context.setVizIsPending(true);
        context.getMarketAction(reqBody);
      }

      if (found && query.market && context.activeViz === "vizCompanies") {
        context.setSelectedEco(found);
        let reqBody = {...context.activeLocation };
        reqBody.slug = found.slug;
        context.getMarketAction(reqBody);
        reqBody.slug = query.market;
        context.getCompaniesAction(reqBody);
        context.setDisplayLoader(true);
        context.setVizIsPending(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allEcoSystemes]);

  useEffect(() => {
    if (context.activeLocation && context.allLocation.communes) {
      context.getEcoSystemesAction(context.activeLocation);
      if (context.activeViz === "vizMarket" && context.selectedEco) {
        context.setDisplayLoader(true);
        let reqBody = context.activeLocation;
        reqBody.slug = context.selectedEco.slug;
        context.setVizIsPending(true);
        context.getEcoSystemesAction(reqBody);
        context.getMarketAction(reqBody);
      }
      if (context.activeViz === "vizCompanies") {
        context.setDisplayLoader(true);
        let reqBody = context.activeLocation;
        reqBody.slug = context.selectedMarket.slug;
        context.getCompaniesAction(reqBody);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context.activeLocation, context.allLocation.communes]);

  // FUNCTION ///
  
  function displayVizMarket(d) {
    let reqBody = context.activeLocation;
    reqBody.slug = d.slug;
    context.getMarketAction(reqBody);
    context.setMarkets([]);
    context.setDisplayLoader(true);
    context.setDisplayNoData(false);
    context.setSelectedEco(d);
    context.setActiveViz("vizMarket");
    handleChangeQuery('eco', d.slug);
    handleChangeQuery('slug', d.slug);
    handleChangeQuery('activeViz', 'vizMarket');
    context.setCompanyIsOpen(false);
  }

  const displayVizCompanies = (d) => {
    let reqBody = context.activeLocation;
    reqBody.slug = d.slug;
    context.setCompanies({});
    context.getCompaniesAction(reqBody);
    context.setDisplayLoader(true);
    context.setDisplayNoData(false);
    context.setActiveViz("vizCompanies");
    handleChangeQuery('slug', d.slug);
    handleChangeQuery('market', d.slug);
    handleChangeQuery('activeViz', 'vizCompanies');
    context.setCompanyIsOpen(false);
  }

  const displayCompanyDetails = (d) => {
    context.setSelectedCompany(d);
    context.setCompanyIsOpen(true);
    context.setDisplayNoData(false);
  }

  function displayVizEco() {
    context.setActiveViz("vizEco");
    handleChangeQuery('activeViz', 'vizEco');
    handleChangeQuery('slug', '');
    handleChangeQuery('eco', '');
    context.setDisplayNoData(false);
    context.setMarkets([]);
  }

  // FUNCTION RENDER COMPONENTS///

  function renderDataViz() {
    if(context.activeViz === "vizEco") {
      return (
        <VizEco
          displayVizMarket={displayVizMarket}
        />
      )
    } else if (context.activeViz === "vizMarket") {
      return (
        <VizMarket
          displayVizEco={displayVizEco}
          displayVizMarket={displayVizMarket}
          displayVizCompanies={displayVizCompanies}
        />
      )
    } else if (context.activeViz === "vizCompanies") {
      return (
        <VizCompanies
          displayCompanyDetails={displayCompanyDetails}
          displayVizEco={displayVizEco}
          companyIsOpen={context.setCompanyIsOpen}
          displayVizMarket={displayVizMarket}
          setCompanyIsOpen={context.setCompanyIsOpen}
        />
      ) 
    } else {
      return <Loader message={"Mise à jour en cours"}/>
    }
  };

  return (
    <div className="explorer">
      {renderDataViz()}
      {context.companyIsOpen && context.selectedCompany && context.selectedMarket?
        <CompanyModal
          displayVizCompanies={displayVizCompanies}
          setCompanyIsOpen={context.setCompanyIsOpen}
          displayVizMarket={displayVizMarket}
          context={context} />
        : <Loader />
      }
      <Header
        displayCompanyDetails={displayCompanyDetails}/>
      <SideBar
        displayVizCompanies={displayVizCompanies}
        displayCompanyDetails={displayCompanyDetails}
      />
      <img src={audiarLogo} className="logo-audiar" alt="logo" width="50px"/>
    </div>
  );
};

export default Explorer;
