import React , { useState , useEffect , useContext } from 'react'
import { authContext } from '../../context/authContext';
// import history from "../../utils/history";

export default function ResetPassword(props) {
  const auth = useContext(authContext);

  const token = props.match.params.token;
  const [ password , setPassword ] = useState();
  const [ confirmPassword , setConfirmPassword ] = useState();
  const [ validateIsDisabled , setValidateIsDisabled] = useState(true);
  const [ message , setMessage ] = useState()

  auth.logout();
  
  function sumbitNewPassword() {
    const confirm = {
      password : password,
      passwordConfirm: confirmPassword,
      token: token
    };
    auth.postNewPassword(confirm)
  }

  useEffect(() => {
    if (password && password.length < 8) {
      console.log(password.length)
      setMessage("Votre mot de passe doit contenir au moins 8 caractères")
    } else {
      setMessage()
      if (confirmPassword === password && password !== undefined && confirmPassword !== undefined ) {
        setValidateIsDisabled(false)
      } else {
        setValidateIsDisabled(true)
      }
    }

  }, [confirmPassword , password])

  return (
    <div className="password-container">
      <span className="error">{message}</span>
      <input className="input" id="reset-password" type="password" placeholder="mot de passe" onChange={(e) => setPassword(e.target.value)}/>
      <input className="input" id="reset-password-confirm" type="password" placeholder="confirmer le mot de passe" onChange={(e) => setConfirmPassword(e.target.value)}/>
      <button
          style={{marginTop: 25 }}
          className={`btn-primary ${validateIsDisabled? "disabled": "" }`}
          disabled={validateIsDisabled}
          onClick={() => sumbitNewPassword()}>
          Valider
        </button>
    </div>
  )
}
