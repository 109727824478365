import React, { useContext } from 'react';
import loaderSVG from '../../assets/images/loader.svg';
import { explorerContext } from '../../context/explorerContext';

export default function Loader(props) {
  const context = useContext(explorerContext);
  return (
    <div className={context.displayLoader? "loader-container message" : "d-none"}>
      <img src={loaderSVG} width={60} height={60} alt="loader"/>
      <span>{props.message}</span>
    </div>
  )
}