import React, { useState , useRef, useEffect, createContext } from 'react';
import {
    getAllSearchAction ,
    getAllLocationAction ,
    getEcoSystemesAction ,
		getMarketAction,
		getAllMarketsAction,
    getCompaniesAction,
} from '../actions/explorerActions';
 
export const explorerContext =  createContext();

export default function ExplorerContextProvider(props) {
	var state = useRef();
	const [ selectedCompany, setSelectedCompany] = useState({});
	const [ companyIsOpen , setCompanyIsOpen ] = useState(false);
	const [ companies, setCompanies] = useState({});
	const [ markets , setMarkets ] = useState([]);
	const [ ecoSystemes , setEcoSystemes ] = useState([]);
	const [ allSearch , setAllSearch ] = useState([]);
	const [ allMarkets , setAllMarkets ] = useState([]);
	const [ allEcoSystemes , setAllEcoSystemes ] = useState([]);
	const [ selectedMarket , setSelectedMarket ] = useState({});
	const [ selectedEco , setSelectedEco ] = useState({});
	const [ activeViz , setActiveViz ] = useState("vizEco");
	const [ selectedYear , setSelectedYear ] = useState();
	const [ allLocation , setAllLocation ] = useState({});
	const [ activeLocation, setActiveLocation] = useState();
	const [ yearEvolution, setYearEvolution ] = useState("");
	const [ evolutionIsActive, setEvolutionIsActive ] =  useState(false);
	const [ displayLoader, setDisplayLoader ] =  useState(true);
	const [ displayNoData , setDisplayNoData] = useState(false);
	const [ vizIsPending , setVizIsPending ] = useState(true);


	useEffect(() => {
		if (selectedEco && displayNoData !== true) {
			if (selectedEco.years && selectedEco.years.filter(item => item.year === selectedYear).length === 0 ) {
				setDisplayNoData(true)
			} else if (selectedEco.years && selectedEco.years.filter(item => item.year === selectedYear)[0].size === 0) {
				setDisplayNoData(true)
			}
		}
	}, [selectedEco , selectedYear , displayNoData]) 

	state = {
		displayNoData: displayNoData,
		setDisplayNoData: (val ) => setDisplayNoData(val),
		displayLoader: displayLoader,
		setDisplayLoader: (val ) => setDisplayLoader(val),
		yearEvolution: yearEvolution,
		setYearEvolution: (val ) => setYearEvolution(val),
		evolutionIsActive: evolutionIsActive,
		setEvolutionIsActive : (val) => setEvolutionIsActive(val),
		selectedYear: selectedYear,
		setSelectedYear: (val) => setSelectedYear(val),
		activeViz: activeViz,
		setActiveViz: (val) => setActiveViz(val),
		companyIsOpen: companyIsOpen,
		setCompanyIsOpen:(val) => setCompanyIsOpen(val),
		selectedCompany: selectedCompany,        
		setSelectedCompany: (val) => setSelectedCompany(val),
		selectedMarket: selectedMarket,
		setSelectedMarket: (val) => setSelectedMarket(val),
		selectedEco: selectedEco,
		setSelectedEco: (val) => setSelectedEco(val),
		markets: markets,
		allSearch: allSearch,
		setMarkets: (val) => setMarkets(val),
		companies: companies,
		setCompanies: (val) => setCompanies(val),
		ecoSystemes: ecoSystemes,
		setEcoSystemes: (val) => setEcoSystemes(val),
		allEcoSystemes: allEcoSystemes,
		setAllEcoSystemes: (val) => setAllEcoSystemes(val),
		allLocation: allLocation,
		setAllLocation: (val)=> setAllLocation(val),
		activeLocation: activeLocation,
		setActiveLocation: (val)=> setActiveLocation(val),

		//EXPLOREUR ACTION
		getAllSearchAction: () => getAllSearchAction().then(
			res => {
				setAllSearch(res.data);
			}
		),
		getAllLocationAction: () => getAllLocationAction().then(
			res => {

				res.data.communes.map(item => { return item.value = item.label} );
				res.data.intercommunautes.map(item => { return item.value = item.label} );
				let allCommunes = {
					"label": "Toutes les communes",
					"location": "all",
					"value": "all",
					"searchType": "communes"
				}
				let allInter = {
					"label": "Toutes les intercommunautés",
					"location": "all",
					"value": "all",
					"searchType": "intercommunautes"
				};
				let allZones = {
					"label": "Toutes les zones d'emploi",
					"value": "all",
					"searchType": "zones"
				};

				let communes = res.data.communes.sort(function(a,b) {
					var x = a.label.toLowerCase();
					var y = b.label.toLowerCase();
					return x < y ? -1 : x > y ? 1 : 0;
				})
				communes.unshift(allCommunes);

				let intercommunautes = res.data.intercommunautes.sort(function(a,b) {
						var x = a.label.toLowerCase();
						var y = b.label.toLowerCase();
						return x < y ? -1 : x > y ? 1 : 0;
				}).map((d) => {
          const inter = {...d}
          const found = communes && communes.find((c) => c.intercommunaute && c.intercommunaute.slug === inter.slug);
          if (found && found.zone.slug) {
            inter.zone = found.zone;
          }
          return inter;
        });
				intercommunautes.unshift(allInter);

				let zones = res.data.zones.filter(z => z.label !== null ).sort(function(a,b) {
						var x = a.label.toLowerCase();
						var y = b.label.toLowerCase();
						return x < y ? -1 : x > y ? 1 : 0;
				})

				zones.unshift(allZones);

				setAllLocation({
					communes: communes,
					intercommunautes: intercommunautes,
					zones : zones,
					sectors: res.data.sectors,
					types: res.data.types,
          ess: res.data.ess,
				});
			}
		),
		getEcoSystemesAction:(obj) => getEcoSystemesAction(obj).then(
			res => {
				setEcoSystemes(res.data);
				setAllEcoSystemes(res.data.nodes);
				setVizIsPending(false)
				if (selectedEco && selectedEco.slug) {
					let foundEco = res.data.nodes.find(item => item.slug === selectedEco.slug);
					if (foundEco) {
						setSelectedEco(foundEco);
					}
				}
			}
		),
		vizIsPending: vizIsPending,
		setVizIsPending: (val) => setVizIsPending(val),
		allMarkets: allMarkets,
		getAllMarketsAction: () => getAllMarketsAction().then(res => {
			setAllMarkets(res.data);
		}), 
		getMarketAction:(obj) => getMarketAction(obj).then(
			res => {
				if (res.data.length > 0) {
					setMarkets(res.data);
					setDisplayNoData(false);
				} else {
					setDisplayLoader(false);
					setMarkets([]);
				}
			}
		),
		getCompaniesAction:(obj) => getCompaniesAction(obj)
			.then(
				res  => {
					if (res.data.companies.length === 0  || res.data.length === 0 ) {
						setDisplayNoData(true);
						setDisplayLoader(false);
						setSelectedMarket(res.data.market);
						setCompanies([]);
					} else {
						setCompanies(res.data.companies)
						setSelectedMarket(res.data.market)
						setDisplayNoData(false);
					}
				}
			).catch(
				err => {
					setDisplayNoData(true);
					setDisplayLoader(false);
					setCompanies([])
				}
			),
  };
    return <explorerContext.Provider value={state}>{props.children}</explorerContext.Provider>
}

export const explorerContextConsumer = explorerContext.Consumer;