import React , { useContext } from 'react';
import dataVisLogo from './../../assets/images/vizioecologo.png';
import { explorerContext } from '../../context/explorerContext';

export default function SideBar(props) {
  const context = useContext(explorerContext);

  function isMarketLabel(d) {
    const market = d.years.find((y) => y.year === context.selectedYear);
    // const eco  = context.selectedEco && context.selectedEco.years.find((y) => y.year === context.selectedYear);
    // console.log((market.size * 100) / eco.size)
    if ((market && market.companies < 5) || market.concentration) {
      return false;
    }
    return true;
  }

  function isCompanyLabel() {

  const effectifs = context.companies
    .filter((item) => parseFloat(item.effectifs[context.selectedYear]) > 0 )
    .map((item) => parseFloat(item.effectifs[context.selectedYear]));

    var sommeTotale = effectifs.reduce((acc, valeur) => acc + valeur, 0);

    // Calcul de 90 % de la somme totale
    var seuil = 0.9 * sommeTotale;
    
    var isSupTo90 = false;

    for (var i = 0; i < effectifs.length; i++) {
      if (effectifs[i] > seuil) {
          isSupTo90 = true;
          break;
      }
    }
      
    if (isSupTo90) {
      return false;
    }
    return true;
  }


  const renderIntro = () => {
    return (
      <div className="accueil">
        <img src={dataVisLogo} alt="logo" width="100%"/>
        <div className="underline"></div>
        <p>
          VizioECO est une plate-forme de datavisualisation de l'économie de la métropole de Rennes et du département d'Ille-et-Vilaine. Les emplois et établissements sont regroupés par écosystème, lecture construite par l’Audiar qui vise à regrouper les activités autour de grandes fonctions dans l’économie (nourrir, déplacer, décarboner, digitaliser, construire et loger, …). Toutes les grandes fonctions de l’économie ne sont pas affichées dans cette dataviz.
        </p>
        <p>
          Les effectifs sont comptés en équivalent temps plein. Seuls les emplois privés sont affichés, hors effectifs intérimaires et apprentis. Les données sont affichées par année, au 31 décembre.
        </p>
        <p><i>Sources : répertoire Sirene Insee, données emplois du GIP-MDS, retraitements Audiar.</i></p>
        <a href="files/Tutoriel-VizioECO.pdf" download>Télécharger le tutoriel en pdf</a>
      </div>
    );
  }

  const renderListMarkets = () => {
    if (context.selectedEco && context.selectedEco.years && context.selectedEco.years.length > 0 ) {
      let markets = [];
      context.markets.map((item) => {
        let found = item.years.filter(item => item.year === context.selectedYear);
        if (found.length > 0 && found[0].size > 0) {
          return markets.push(item);
        }
        return item;
      })
      
      return (
        <>
          <h2>Liste des Marchés</h2>
          <h3>{context.selectedEco.label}<br/>
            <span>
              {context.selectedEco.years.filter(item => item.year === context.selectedYear).length > 0 ?
                context.selectedEco.years.filter(item => item.year === context.selectedYear)[0].size
                :
                0
              } postes salariés privés<br/>
              <span className="date"> au 31/12/{context.selectedYear}</span>
            </span>
          </h3>
          <div className="list">
            {markets.sort((a, b) => {
                a.size = a.years.filter(item => item.year === context.selectedYear)[0].size
                b.size = b.years.filter(item => item.year === context.selectedYear)[0].size
                return a.size - b.size;
              }).reverse().map((item, index) => {
                if (item.size !== 0) {
                  return (
                    <div key={index} className="list-item">
                      <h4 onClick={() => props.displayVizCompanies(item)}>{item.label}</h4>
                      {isMarketLabel(item) && <span>{item.years.filter(item => item.year === context.selectedYear)[0].size} postes salariés privés</span>}
                    </div>
                  )
                }
                return null;
            })}
          </div>
        </>
      );
    }
  }

  const renderListCompanies = () => {
    if (context.companies.length > 0 && context.selectedMarket.years && context.selectedMarket.years.length > 0) {
      const countCompanies = context.selectedMarket.years.find(item => item.year === context.selectedYear).companies;
      return (
        <>
          <h2>{context.companies.length < 30 ? "Liste des entreprises" : "30 premiers établissements employeurs" }</h2>
          <h3>{context.selectedMarket.label}<br/>
            <span>
              {context.selectedMarket.years.filter(item => item.year === context.selectedYear).length > 0 ?
                context.selectedMarket.years.filter(item => item.year === context.selectedYear)[0].size
                :
                0
              } postes salariés privés
            </span>
            <br/>
            <span className="date"> au 31/12/{context.selectedYear}</span>
          </h3>
          <div className="list">
            {context.companies.filter(item => parseFloat(item.effectifs[context.selectedYear]) > 0 ).sort((a, b) => {
                return parseInt(a.effectifs[context.selectedYear]) - parseInt(b.effectifs[context.selectedYear]);
              }).reverse().map((item, index) => {
                if (index < 30 ) {
                  return (
                    <div key={index} className="list-item">
                      <h4 onClick={() => props.displayCompanyDetails(item)}>{item.label}</h4> 
                      {isCompanyLabel(item) && countCompanies > 5 &&<span>{renderCountSlice(parseFloat(item.effectifs[context.selectedYear]))}</span>}
                    </div>
                  );
                }
                return null ; 
              }
            )}
          </div>
        </>
      )
    }
  }

  const renderCountSlice = (count) => {
    if ( count >= 2000 ) {
      return "2000 postes salariés privés et plus"
    } else if (count >= 1000 ) {
      return "1000 à 1999 postes salariés privés"
    } else if (count >= 500) {
      return "500 à 999 postes salariés privés"
    } else if (count >= 200 ) {
      return "200 à 499 postes salariés privés"
    } else if (count >= 100 ) {
      return "100 à 199 postes salariés privés"
    } else if (count >= 50 ) {
      return "50 à 99 postes salariés privés"
    } else if (count >= 20 ) {
      return "de 20 à 49 postes salariés privés"
    } else {
      return "mois de 20 emplois"
    }
  }

  const renderSideBar = () => {
    if(context.activeViz === "vizEco") {
      return renderIntro();
    } else {
      return (
        <div className="list-container">
          <img src={dataVisLogo} alt="logo" width="70%"/>
          {context.activeViz === "vizMarket" ? renderListMarkets() : renderListCompanies() }
        </div>
      )
    }
  }

  return (
    <div className="sidebar">
      {renderSideBar()}
    </div>
  );
}