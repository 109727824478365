import React, { useContext } from 'react';
import { explorerContext } from '../../context/explorerContext';

export default function NoData() {
  const context = useContext(explorerContext);
  return (
    <div className={context.displayNoData? "loader-container" : "d-none"}>
      <h2>Données non disponibles</h2>
    </div>
  );
}