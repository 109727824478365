import { getData , postData , postPassword} from './index';

export function loginAction(obj) {
  return new Promise(function(resolve, reject) {
    postData("auth/login",resolve,reject , obj);
  })
};

export function getUserAction() {
  return new Promise(function(resolve, reject) {
    getData("user/me",resolve,reject , true);
  })
};

export function resetPasswordAction(email) {
  return new Promise(function(resolve, reject) {
    postData("auth/reset" ,resolve,reject , email , false);
  })
};

export function postNewPassword(obj , token  ) {
  return new Promise(function(resolve, reject) {
    postPassword("auth/password" ,resolve,reject , obj , token );
  })
};


