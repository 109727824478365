import React, { useState , useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { explorerContext } from '../../context/explorerContext.js';
import { handleChangeQuery, parseQuery } from '../../utils/utils.js';

import AutoComplete from '../Partials/AutoComplete';
import Filter from './Filters';
// import Nav from './Nav';

export default function Header(props) {
  const context = useContext(explorerContext);
  const [ selectedCommune , setSelectedCommune ] = useState("Toutes les communes");
  const [ selectedIntercommunaute , setSelectedIntercommunaute ] = useState("Toutes les intercommunautés");
  const [ selectedTypes , setSelectedTypes ] = useState([]);
  const [ selectedSectors , setSelectedSectors ] = useState([]);
  const [ selectedEss, setSelectedEss ] = useState([]);
  const [ selectedZones , setSelectedZones ] = useState({
    "label": "Toutes les zones d'emploi",
    "value": "all",
  });

  const { search } = useLocation();
  const query = parseQuery(search);
  
  const zones = context.allLocation && context.allLocation.zones && context.allLocation.zones.length && context.allLocation.zones.map(t => ({ value: t.slug , label: t.label }))
  const intercommunautes = context.allLocation && context.allLocation.intercommunautes;
  const communes = context.allLocation.communes && context.allLocation.communes;
  
  useEffect(() => {
    if (query && context.allLocation) {
      const zonesFound = zones && zones.find((z) => z.value === query.zones) 
      if (zonesFound) {
        setSelectedZones(zonesFound)
      }
      const intercommunautesFound = intercommunautes && intercommunautes.find((z) => z.slug === query.location) 
      if (intercommunautesFound) {
        setSelectedIntercommunaute(intercommunautesFound.value);
      }
      const interFound = intercommunautes && intercommunautes.find((z) => z.slug === query.intercommunaute)

      if (interFound) {
        setSelectedIntercommunaute(interFound.label);
        setSelectedZones(interFound.zone)
      }
      const communesFound = communes && communes.find((z) => z.label === query.location)
      if (communesFound) {
        setSelectedCommune(communesFound.label);
      }
      if (query.sectors && context.allLocation.sectors) {
        let sectors = JSON.parse(query.sectors);
        const selected = context.allLocation.sectors.map(
          (s) => ({ value: s.slug , label: s.label }))
          .filter((s) => sectors.find((d) => d === s.value));
        setSelectedSectors(selected);
      }
      if (query.types && context.allLocation.types) {
        let types = JSON.parse(query.types);
        const selected = context.allLocation.types.map(
          (s) => ({ value: s.slug , label: s.label }))
          .filter((s) => types.find((d) => d === s.value));
        setSelectedTypes(selected);
      }
      if (query.ess && context.allLocation.ess) {
        let ess = JSON.parse(query.ess);
        const selected = context.allLocation.ess.map(
          (s) => ({ value: s.slug , label: s.label }))
          .filter((s) => ess.find((d) => d === s.value));
        setSelectedEss(selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context.allLocation.communes]);

  const handleChangeCommune = (selectedOption) => {
    let obj = {
      ...context.activeLocation,
      searchType: selectedOption.searchType,
      location: selectedOption.value,
    }
    handleChangeQuery('zones', '');
    handleChangeQuery('searchType', selectedOption.searchType);
    handleChangeQuery('location', selectedOption.slug);
    setSelectedCommune(selectedOption.label)
    context.setDisplayLoader(true);
    context.setDisplayNoData(false);

    if (selectedOption.intercommunaute) {
      setSelectedIntercommunaute(selectedOption.intercommunaute.label);
      setSelectedZones(selectedOption.zone);
      handleChangeQuery('intercommunaute', selectedOption.intercommunaute.slug);
    };
    if (selectedOption.label === "Toutes les communes") {
      setSelectedIntercommunaute("Toutes les intercommunautés")
    };
    context.setActiveLocation(obj);
    if (context.activeViz === "vizMarket") {
      context.setMarkets([]);
    };
  };

  const handleChangeIntercommunaute = (selectedOption) => {
    let obj = {
      ...context.activeLocation,
      searchType: selectedOption.searchType,
      location: selectedOption.value,
    };
    context.setDisplayLoader(true);
    context.setDisplayNoData(false);
    handleChangeQuery('zones', '' );
    handleChangeQuery('searchType', selectedOption.searchType);
    handleChangeQuery('location', selectedOption.slug);
    setSelectedZones(selectedOption.zone);

    setSelectedCommune("Toutes les communes");
    setSelectedIntercommunaute(selectedOption.label);
    handleChangeQuery('intercommunaute', selectedOption.slug);

    context.setActiveLocation(obj);

    if (context.activeViz === "vizMarket") {
      context.setMarkets([]);
    }; 
  };

  const handleChangeTypes = (types) => {
    let obj = Object.assign({} , context.activeLocation );

    setSelectedTypes(types);
    obj.types = types ? types.map(item => item.value) : [];
    handleChangeQuery('types', JSON.stringify(obj.types));

    context.setDisplayLoader(true);
    context.setDisplayNoData(false);

    context.setActiveLocation(obj);

    if (context.activeViz === "vizMarket") {
      context.setMarkets([]);
    }; 
  };

  const handleChangeEss = (array) => {
    let obj = Object.assign({} , context.activeLocation );
    setSelectedEss(array)
    obj.ess = array ? array.map(item => item.value) : [];
    handleChangeQuery('ess', JSON.stringify(obj.ess));
    context.setDisplayLoader(true);
    context.setDisplayNoData(false);

    context.setActiveLocation(obj);

    if (context.activeViz === "vizMarket") {
      context.setMarkets([]);
    }; 
  };

  const handleChangeSectors = (sectors) => {
    let obj = Object.assign({} , context.activeLocation );
    setSelectedSectors(sectors)
    obj.sectors = sectors ? sectors.map(item => item.value) : [];
    handleChangeQuery('sectors', JSON.stringify(obj.sectors));
    context.setDisplayLoader(true);
    context.setDisplayNoData(false);

    context.setActiveLocation(obj);

    if (context.activeViz === "vizMarket") {
      context.setMarkets([]);
    }; 
  };


  const handleChangeZones = (zones) => {
    let obj = Object.assign({} , context.activeLocation );
    console.log(context.activeLocation);
    setSelectedZones(zones)
    obj.zones = zones.value;
    handleChangeQuery('zones', zones.value );
    handleChangeQuery('searchType', 'communes');
    handleChangeQuery('location', '');
    handleChangeQuery('intercommunaute', '');

    setSelectedIntercommunaute("Toutes les intercommunautés");
    setSelectedCommune("Toutes les communes");

    context.setDisplayLoader(true);
    context.setDisplayNoData(false);

    context.setActiveLocation(obj);

    if (context.activeViz === "vizMarket") {
      context.setMarkets([]);
    }; 
  };

  return (
    <div className="header">
      <Filter
        selectedCommune={selectedCommune}
        selectedIntercommunaute={selectedIntercommunaute}
        selectedTypes={selectedTypes}
        selectedSectors={selectedSectors}
        selectedZones={selectedZones}
        selectedEss={selectedEss}
        handleChangeZones={handleChangeZones}
        handleChangeSectors={handleChangeSectors}
        handleChangeTypes={handleChangeTypes}
        handleChangeIntercommunaute={handleChangeIntercommunaute}
        handleChangeEss={handleChangeEss}
        handleChangeCommune={handleChangeCommune}/>
      <AutoComplete
        handleChangeIntercommunaute={handleChangeIntercommunaute}
        handleChangeCommune={handleChangeCommune}
        displayCompanyDetails={props.displayCompanyDetails}/>
      {/* <Nav /> */}
    </div>
  );
}