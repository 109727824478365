import React from 'react';

export default function CheckBox({ handleChange , value , label }) {
  return (
    <label className="checkbox">{label}
      <input onChange={() => handleChange()} type="checkbox" checked={value ? 'checked': ''}/>
      <span className={`checkmark ${value ? 'checked': ''}`}></span>
    </label>
  )
}
