import React from "react";
import { Router ,  Route, } from "react-router-dom";
import history from "../utils/history";

import '../scss/app.scss';

// import AdminContextProvider from "../context/adminContext";
import AuthContextProvider from "../context/authContext";

// import Admin from './Pages/Admin';
// import LoginModale from './Partials/LoginModale';
// import UserModale from './Partials/UserModale';
import Explorer from './Pages/Explorer';
import ExplorerContextProvider from '../context/explorerContext';
import ResetPassword from "./Pages/ResetPassword";

export default function App() {
  return (
    <AuthContextProvider>
      <Router history={history}>
        {/* <LoginModale /> */}
        <Route exact path="/password/:token" render={(props) => <ResetPassword {...props} />} />
        {/* <AdminContextProvider>
          <Route exact path="/admin" render={AdminComponent} />
        </AdminContextProvider> */}
        <ExplorerContextProvider>
          <Route exact path="/" render={ExplorerComponent} />
        </ExplorerContextProvider>
      </Router>
    </AuthContextProvider>
  );
}

function ExplorerComponent() {
  return (
      <Explorer />
  );
}


