import React, {useContext  } from 'react';
import close from '../../assets/images/close.png';

import { explorerContext } from '../../context/explorerContext';

export default function CompanyModal(props) {
  const context = useContext(explorerContext);
  //TODO change close icon && modale style ?

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="close-modal">
          <img src={close} alt="btn-close" onClick={() => props.setCompanyIsOpen(false)}/>
        </div>
        <h2>{context.selectedCompany.label}</h2>
        <p className="adress">{context.selectedCompany.adress.voie} {context.selectedCompany.adress.codegeo} {context.selectedCompany.adress.commune}</p>
        <ul className="modal-list border-eco">
          <span>ECOSYSTEMES</span>
          {context.selectedCompany.ecosystemes.map((item,index) => {
            return (
              <li className="modal-link"
                key={index}
                onClick={() => props.displayVizMarket(props.context.allEcoSystemes.filter(i => i.slug  === item)[0])}
                style={{ backgroundColor : props.context.allEcoSystemes.filter(i => i.slug  === item)[0].color }}
              >
              {props.context.allEcoSystemes.find(i => i.slug  === item).label}
              </li>
            )
          })}
        </ul>
        <ul className="modal-list">
          <span>MARCHES</span>
            <li className="modal-link"
              onClick={() => props.displayVizCompanies(context.selectedMarket)}
              style={{ backgroundColor : props.context.selectedEco.color }}
            >
              {context.selectedMarket.label}
            </li>
        </ul>
      </div>
    </div>
  );
}