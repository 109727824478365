import React, { useEffect, useContext , useState }from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';

import { explorerContext } from '../../context/explorerContext.js';
import { hexToRGBa } from '../../utils/utils';
import Loader from '../Partials/Loader';
import NoData from '../Partials/NoData';

export default function VizEco(props) {
  const context = useContext(explorerContext);
  const [ lastSelectedYear ,setLastSelectedYear ] = useState(context.selectedYear);
  
  useEffect(() => {
    if (context.ecoSystemes && context.ecoSystemes.nodes) {
      drawEcoSystemes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context.ecoSystemes , context.yearEvolution , context.selectedYear]);

  useEffect(() => {
    if (context.selectedYear !== lastSelectedYear ) {
      drawEcoSystemes();
  
      // context.getEcoSystemesAction(context.activeLocation)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context.selectedYear]);

  const drawEcoSystemes = () => {
    context.setDisplayNoData(false);
    setLastSelectedYear(context.selectedYear);
    d3.selectAll("#svg-links > *").remove();
    d3.selectAll("#graph > *").remove();

    let width,height;
    let div = d3.select("#graph");

    let svg = d3.select("#svg-links")
      .style("pointer-events", "none");

    let simulationEco;
    let minNodeSizeViz = 50;
    let maxNodeSizeViz = context.evolutionIsActive ? 100 : 120;
    let decalageX = 0;
    let decalageY = 200;

    let linksData =_.cloneDeep(context.ecoSystemes.links);
    
    let ecoSystemes = [];
    let links = [];
    if (!context.ecoSystemes.nodes) return null;
    _.cloneDeep(context.ecoSystemes.nodes).map(item => {
      let notFound = item.years.filter(item => item.year === context.selectedYear);

      if (notFound.length === 0) return null;
      
      let selectedYear = item.years.filter(item => item.year === context.selectedYear)[0];
      let yearEvolution = item.years.filter(item => item.year === context.yearEvolution)[0];
      if ( item.years.length > 0 && selectedYear.size > 0 ) {
        let yearValue = selectedYear.size;
        if (context.evolutionIsActive === true ) {
          if (yearEvolution && yearEvolution.size) {
            let yearEvoValue = yearEvolution.size
            let evolutionValue = yearValue - yearEvoValue;
            if (evolutionValue > 0) {
              item.evolColor = '#35D980';
            } else {
              item.evolColor = '#FF4959';
            }
            item.size = evolutionValue
            return ecoSystemes.push(item);
          }
        } else {
          item.size = yearValue
          return ecoSystemes.push(item);
        }
      }
      return item;
    });

    if (ecoSystemes.length === 0 ) {
      context.setDisplayLoader(false);
      context.setDisplayNoData(true);
      return null
    } 

    let  maxNode = _.maxBy(ecoSystemes, 'size').size;
    let  minNode = _.minBy(ecoSystemes, 'size').size;

    var maxNodeSize = maxNode

    if (maxNode < Math.abs(minNode)) {
      maxNodeSize = Math.abs(minNode)
    }

    var setNodeScale = d3.scaleLinear()
    .domain([ 0 , maxNodeSize])
    .range([minNodeSizeViz , maxNodeSizeViz]);

    _.each(ecoSystemes, function (node) {
      node.sizeViz = setNodeScale(Math.abs(node.size))
    });

    //SET LINK SIZE

    if (context.ecoSystemes.links && context.ecoSystemes.links.length > 0 ) {
      let maxLinkSize = _.maxBy(context.ecoSystemes.links, 'count').count;
      let minLinkSize = _.minBy(context.ecoSystemes.links, 'count').count;
  
      var setLinkScale = d3.scaleLinear()
      .domain([minLinkSize, maxLinkSize])
      .range([2 ,  10]);
  
      linksData.map(link => {
        let targetFound =_.find(ecoSystemes, ['slug', link.target])
        let sourceFound =_.find(ecoSystemes, ['slug', link.source])
        if (targetFound !== undefined && sourceFound !== undefined) {
          link.size = setLinkScale(link.count)
          return links.push(link)
        }
        return link
      })
    }

    let dataSet = {
      nodes: ecoSystemes,
      links: links
    }

    dataSet.nodes.sort(function(a,b){ return b.sizeViz - a.sizeViz; });
      


    if(simulationEco){
      simulationEco.stop();
    };

    width = document.querySelector("#graph").clientWidth - decalageX;
    height = document.querySelector("#graph").clientHeight - decalageY;

    simulationEco = d3.forceSimulation()
      .force("forceX", d3.forceX(0).strength(0.2))
      .force("forceY", d3.forceY(0).strength(0.2))
      .force("link", d3.forceLink().id(function(d) { return d.slug; }).strength(0.1))
      .force("collide", d3.forceCollide().strength(1).radius((d) => { return d.sizeViz } ));

    div
      .attr("width", width + "px")
      .attr("height", height + "px")
      .style("transform", function(d) { return "translate("+ width/2 + "px," + height/2 + "px)" });

    var link = svg.selectAll("link")
      .data(dataSet.links)
      .enter().append("path")
      .attr("class", "link")
      .style("transform", function(d) { return "translate("+ width/2 + "px," + height/2 + "px)" })
      .style("fill", "none");

    div.selectAll(".ecosysteme-label")
      .data(dataSet.nodes)
      .enter()
      .append("p")
      .attr("class", "ecosysteme-label")
      .style("color", function(d) {
        if (context.evolutionIsActive === true) {
          return d.evolColor
        } else {
          return d.color
        }
      })
      .text(function (d) {
        return d.label;
      });
    
    div.selectAll(".circle")
      .data(dataSet.nodes)
      .enter()
      .append("div")
      .attr("class", "circle")
      .attr("id", function(d) { return d.slug })
      .style("transform", function(d) { return "translate("+ 0 + "px," + 0 + "px)" })
      .style("background-color", function(d){
        if (context.evolutionIsActive === true) {
          return d.evolColor
        } else {
          return d.color
        }
      })
      .style("border-radius", function (d) {
      let value = d.sizeViz;
        return value + "px";
      })
      .style("box-shadow", function (d) {
      let value = 5;
        return "0px 0px 0px " + value + "px " + hexToRGBa(d.color, 0.1);
      })
      .style("background-image", function (d) {
        return "url(./images/icons/" + d.slug + ".png)"
      })
      .on("click", function (d) {
        simulationEco.stop()
        props.displayVizMarket(d);
      })
      .call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended))
      .on("mouseover", function (d) {

        let allLinks =[]
        link
          .attr("stroke", function(l) {
            if (d.slug === l.source.slug || d.slug === l.target.slug) {
              allLinks.push(l)
              return "#C6C6C6"
            } 
          })
          .attr("stroke-width", function(l) {
            if (d.slug === l.source.slug || d.slug === l.target.slug) {
              return l.size
            } 
          });

        d3.select(this)
          .select('.circle-hover')
          .style('display', 'flex')
          .style("background-color", hexToRGBa("#ffffff", 0.9))
          .style("box-shadow", function (d) {
            let value = 5;
              return "0px 0px 0px " + value + "px " + hexToRGBa(d.color, 0.05);
            });
      })
      .on("mouseout", function (d) {
        d3.selectAll('.circle')
          .transition()
          .duration(300)
          .style("background-color", function (data) {
            if (context.evolutionIsActive === true) {
              return hexToRGBa(data.evolColor);
            } else {
              return hexToRGBa(data.color);
            }
          });

        d3.selectAll('.circle-hover')
          .style('display', 'none');

        d3.selectAll(".link")
          .attr("stroke", "white")
          .attr("stroke-width", "0")
        })
        .append("div")
        .attr("class", "circle-hover")
        .style("height", function (d) {
        let value = d.sizeViz;
          return value + "px";
        })
        .style("width", function (d) {
        let value = d.sizeViz;
          return value + "px";
        })
        .style("background-color", function(d){
          if (context.evolutionIsActive === true) {
            return d.evolColor;
          } else {
            return d.color;
          }
        })
        .style("border-radius", function (d) {
        let value = d.sizeViz;
          return value + "px";
        })
        .append("p")
        .attr("class", "circle-hover-text")
        .style('min-width', '200px')
        .style('text-align', 'center')
        .style("margin-top", function (d) {
        let value = d.sizeViz / 3;
          return value + "px";
        })
        .html(function (d) {
        let value = d.size
          if (context.evolutionIsActive === true && d.size > 0 ) {
            return "<span style='font-size:"
            + (d.sizeViz / 3) + "px; line-height:" + (d.sizeViz / 4)
            + "px; font-family: roboto-black;'>+"+ value
            + "</span><br/><span>postes salariés privés</span>";
          } else {
            return "<span style='font-size:"
            + (d.sizeViz / 3) + "px; line-height:"
            + (d.sizeViz / 4) + "px; font-family: roboto-black;'>"
            + value
            + "</span><br/><span>postes salariés privés</span>";
          }
        });
      
    
    let ticked = function() {
        link
          .attr("d", function(d) {
          let middleSource = d.source.sizeViz/2;
          let middleTarget = d.target.sizeViz/2;
          var dx = d.target.x - d.source.x,
              dy = d.target.y - d.source.y,
              dr = Math.sqrt(dx * dx + dy * dy);
          return "M" + (d.source.x + middleSource) + "," + (d.source.y + middleSource)  + "A" + dr + "," + dr + " 0 0,1 " + (d.target.x + middleTarget) + "," + (d.target.y + middleTarget) ;
          });

        div.selectAll(".circle")
          .transition()
          .duration(200)
          .ease(d3.easeLinear)
          .style("width", function(d){  return d.sizeViz + "px"})
          .style("height", function(d){  return d.sizeViz + "px" })
          .style("transform", function(d) { return "translate("+ d.x + "px," + d.y + "px)" });
        
        div.selectAll(".ecosysteme-label")
          .transition()
          .duration(200)
          .ease(d3.easeLinear)
          .style("transform", function (d) {
          return  "translate(" + (d.x+d.sizeViz / 2 - this.offsetWidth / 2) + "px," + (d.y + d.sizeViz) + "px)"
        });
    }
    
    context.setDisplayLoader(false);

    simulationEco
      .nodes(dataSet.nodes)
      .on("tick", ticked);
      
    simulationEco
      .force("link")
      .links(dataSet.links);

    function dragstarted(d) {
      if (!d3.event.active) simulationEco.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    
    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function dragended(d) {
      if (!d3.event.active) simulationEco.alphaTarget(0).restart();
      d.fx = null;
      d.fy = null;
    } 
  }
  
  d3.select(window).on('resize', drawOnResize);
  
  function drawOnResize() {
    if(context.ecoSystemes.nodes && document.querySelector("#graph")) {
      drawEcoSystemes()
    }
  }

  return (
    <>
      <NoData />
      <Loader />
      <svg id="svg-links" className="dataviz-container"></svg>
      <div id="graph" className="dataviz-container">
      </div>
    </>
  );
}