
import { getData , postData } from './index';

//search/all/
export function getAllSearchAction() {
  return new Promise(function(resolve, reject) {
    getData("ecosysteme/search/all",resolve,reject);
  })
};

//location/all/
export function getAllLocationAction() {
  return new Promise(function(resolve, reject) {
    getData("ecosysteme/location/all",resolve,reject);
  })
};

//ecosysteme/ { location slug }
export function getEcoSystemesAction( obj) {
  if (obj.zones === '') {
    delete obj.zones;
  }
  return new Promise(function(resolve, reject) {
    postData("ecosysteme/ecosysteme",resolve,reject , obj);
  })
};

//markets/ {location , slug}
export function getMarketAction( obj ) {
  return new Promise(function(resolve, reject) {
    postData("ecosysteme/markets/",resolve,reject , obj);
  })
}

export function getAllMarketsAction() {
  return new Promise(function(resolve, reject) {
    getData("ecosysteme/marches/",resolve,reject );
  })
}

//companies { location ,slug }
export function getCompaniesAction( obj ) {
  return new Promise(function(resolve, reject) {
    postData("ecosysteme/companies/",resolve,reject , obj);
  })
}
