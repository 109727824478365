import React, { useState , useContext , useEffect } from 'react';
import iconFilterWhite from '../../assets/images/list-white.svg';
import arrow from '../../assets/images/arrow.svg';
import Select, { components } from 'react-select';
import { explorerContext } from '../../context/explorerContext';
import { handleChangeQuery } from '../../utils/utils';
import CheckBox from './CheckBox';

export default function Filter(props) {
  const context = useContext(explorerContext);

  const [ filterIsActive, setFilterIsActive] = useState(false);
  const [ filterStyle, setFilterStyle ] = useState("");
  const [ yearsList, setYearsList] = useState([]);
  const [ evolutionList, setEvolutionList ] = useState([]);

  const btnStyle = { backgroundColor: "#0092c5", color: "#fff"};
  const btnStyleActive = { backgroundColor: "#fff", color: "#0092c5" };
  const essOptions = context.allLocation && context.allLocation.ess && context.allLocation.ess.map((t) => ({ value: t.slug , label: t.label }))
  const isEss = props.selectedEss && props.selectedEss.find((ess) => ess.value === "VRAI")
  useEffect(() => {
    if (context.ecoSystemes.nodes && context.ecoSystemes.nodes.length > 0 ) {
      let years = [];
      let evolutionList = [];
      context.ecoSystemes.count.map(item => {
        if (context.yearEvolution !== item.year) {
          return years.push({ value: item.year, label: item.year })
        }
        return item;
      })
      if (years.length > 0) {
        const lastYear = years.sort((a,b) => parseInt(b.value) - parseInt(a.value))[0];
        if (!context.selectedYear) {
          handleChangeYear(lastYear);
        }
        setYearsList(years);
      }
      context.ecoSystemes.nodes[0].years.map(item => {
        if (item.year < context.selectedYear && context.activeViz !== "vizCompanies" ) {
          return evolutionList.push({ value: item.year, label: "voir l'évolution depuis " + item.year });
        }
        return item;
      });
      evolutionList.unshift({ value: "", label: "sans montrer l'évolution"});
      setEvolutionList(evolutionList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[context.ecoSystemes, context.selectedYear , context.yearEvolution , context.activeViz]);

  const customFilters = {
    container: (provided, state) => ({
      ...provided,
      fontFamily: 'Roboto',
      margin: 10,
      border: "1px solid #ccc",
      color: "#0092c5",
      minHeight: 32,
      borderRadius: 5,
      transition:
        "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
      "&:hover": {
        boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)"
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Roboto',
      cursor: "pointer",
      color:'#58585a',
      backgroundColor: state.isFocused ? 'rgb(238, 237, 237)': 'transparent',
      transition:"all 1s cubic-bezier(0.075, 0.82, 0.165, 1)",
      paddingLeft: 12,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),
    control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "transparent",
        marginTop: -4,
        border: '0 !important',
   // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex:600,
        fontSize: 13,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color:'#0092c5',
        fontSize: 13,
        fontFamily: 'Roboto',
    }),
    input: (provided, state) => ({
      ...provided,
      color:'#0092c5',
      fontSize: 13,
      fontFamily: 'Roboto',
  }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: 13,
  })
  }

  const displayFilters = () => {
    setFilterIsActive(!filterIsActive)
    if (!filterIsActive) {
      setFilterStyle("filters-active")
    } else {
      setFilterStyle("")
    }
  }
  
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrow} alt="arrow" className={props.selectProps.menuIsOpen? "arrow arrow-up": "arrow arrow-down" } />
      </components.DropdownIndicator>
    );
  };

  const handleChangeYear = (selectedOption) => {
    handleChangeQuery('year', selectedOption.value );
    context.setSelectedYear(selectedOption.value);
  };

  const handleChangeYearsEvolution = (selectedOption) => {
    if (selectedOption.value === "") {
      context.setYearEvolution("");
      handleChangeQuery('evolution', "");
      context.setEvolutionIsActive(false);
    } else {
      handleChangeQuery('evolution', selectedOption.value );
      context.setYearEvolution(selectedOption.value);
      context.setEvolutionIsActive(true);
    }
  }

  function compareYears(a, b) {
    if (parseFloat(a.value) > parseFloat(b.value)) return -1;
    if (parseFloat(b.value) > parseFloat(a.value)) return 1;
    return 0;
  }

  function renderTotalEco() {
    let size = 0;
    let companies = 0;
    if (context.ecoSystemes.count) {
      let found = context.ecoSystemes.count.find(y => y.year === context.selectedYear);
      if(found) {
        size = found.size;
        companies = found.companies;
      }
      return <><br/><span>{size} postes salariés privés - {companies} établissements employeurs</span></>
    } else {
      return null;
    }

  }

  const renderFilters = () => {
    if (context.allLocation.communes && context.allLocation.intercommunautes) {
      return (
        <div className={!filterIsActive? "d-none": "d-block"}>
          <Select
            value={yearsList.filter(option => option.label === context.selectedYear)}
            isSearchable={true}
            styles={customFilters}
            onChange={(val) => handleChangeYear(val)}
            components={{ DropdownIndicator }}
            options={yearsList.sort(compareYears)} />
          {context.allLocation.zones && context.allLocation.zones.length > 0 &&
            <Select
              value={props.selectedZones}
              isSearchable={false}
              styles={customFilters}
              placeholder="Zones"
              onChange={(val) => props.handleChangeZones(val)}
              components={{ DropdownIndicator }}
              options={context.allLocation.zones.map(t => ({ value: t.slug , label: t.label }))} />
          }
          {context.allLocation.intercommunautes.length > 0 &&
            <Select
              isSearchable={true}
              styles={customFilters}
              onChange={(val) => props.handleChangeIntercommunaute(val)}
              components={{ DropdownIndicator }}
              value={context.allLocation.intercommunautes.filter(option => option.label === props.selectedIntercommunaute)}
              options={context.allLocation.intercommunautes} />
          }
          {/* <Select
            isSearchable={true}
            styles={customFilters}
            onChange={(val) => props.handleChangeCommune(val)}
            components={{ DropdownIndicator }}
            value={context.allLocation.communes.filter(option => option.label === props.selectedCommune)}
            options={context.allLocation.communes} /> */}
             {evolutionList && evolutionList.length > 0 &&
              <Select
                value={evolutionList.filter(option => option.value === context.yearEvolution)}
                isSearchable={true}
                styles={customFilters}
                onChange={(val) => handleChangeYearsEvolution(val)}
                components={{ DropdownIndicator }}
                options={evolutionList} />
                }
          {/* <Select
            value={props.selectedSectors}
            isSearchable={true}
            styles={customFilters}
            isMulti
            placeholder="Secteurs d'activité"
            onChange={(val) => props.handleChangeSectors(val)}
            components={{ DropdownIndicator }}
            options={context.allLocation.sectors.map(t => ({ value: t.slug , label: t.label }))} /> */}
            {context.allLocation.types && context.allLocation.types.length > 0 &&
            <Select
              value={props.selectedTypes}
              isSearchable={false}
              styles={customFilters}
              isMulti
              placeholder="Types d'activité"
              onChange={(val) => props.handleChangeTypes(val)}
              components={{ DropdownIndicator }}
              options={context.allLocation.types.map(t => ({ value: t.slug , label: t.label }))} />
            }
          {/* <Select
            value={props.selectedEss}
            isMulti
            isSearchable={false}
            styles={customFilters}
            placeholder="ESS"
            onChange={(opt) => props.handleChangeEss(opt)}
            components={{ DropdownIndicator }}
            options={context.allLocation.ess.map((t) => ({ value: t.slug , label: t.label }))} /> */}
          <CheckBox
            label="Visualiser l'ESS"
            value={isEss}
            handleChange={() => props.handleChangeEss(essOptions.filter((ess) => isEss ? ess.value === "FAUX" : ess.value === "VRAI"))}
          />
        </div>
      )
    }
  }
  return (
    <div className="filters-container">
      {context.allLocation.communes && context.allLocation.intercommunautes &&
        <>
          <p className="filter-resume">
            <span className="bold">{context.selectedYear}
            {props.selectedIntercommunaute && " / " + props.selectedIntercommunaute}
            {props.selectedCommune && " / " + props.selectedCommune}
            </span>
            {context.activeViz === "vizEco" && renderTotalEco()}
          </p>
        
          <div className={"filters " + filterStyle }>
            <button className="filters-btn" onClick={() => displayFilters()} style={!filterIsActive ? btnStyle : btnStyleActive }>
              <img src={!filterIsActive ? iconFilterWhite: arrow } className="icon-filter" alt="icon-filter"/>
              <span>Filtres</span>
            </button>
            {renderFilters()}
          </div>
        </>
      }
    </div>
  )
}