import React, { useState, createContext } from 'react';
import history from '../utils/history';
import { loginAction, resetPasswordAction , postNewPassword } from '../actions/authActions';

export const authContext =  createContext();

export default function AuthContextProvider(props) {
  // VIEW CONST
  const [ activePage , setActivePage ] = useState('explorer');
  const [ loginModaleIsActive , setLoginModaleIsActive ] = useState(false);
  const [ userModaleIsActive , setUserModaleIsActive ] = useState(false);

  // STATE CONST
  const [ activeUser , setActiveUser ] = useState();
  const [ loginStatus , setLoginStatus ] = useState();
  const [ isAdmin , setIsAdmin ] = useState(false);
  

  // STATE 
	const dataState = {
    activeUser: activeUser,
    isAdmin : isAdmin,
    loginStatus: loginStatus
  };

  // INTERFACE STATE
  const viewState = {
    activePage: activePage,
    setActivePage: (string) => setActivePage(string),

    loginModaleIsActive: loginModaleIsActive,
    setLoginModaleIsActive: (bool) =>setLoginModaleIsActive(bool),

    userModaleIsActive: userModaleIsActive,
    setUserModaleIsActive: (bool) => setUserModaleIsActive(bool)
    
  };

  // READ TOKEN AND GET USER INFO

  // useEffect(() => {
  //   if (localStorage.getItem('token')) {
  //     getUserAction().then(
  //       res  => {
  //         setActiveUser(res.data)
  //         setLoginModaleIsActive(false)
  //         if (res.data.role.key < 2) {
  //           setIsAdmin(true);
  //         }
  //       }
  //     ).catch(
  //       err => {
  //         console.log('error' ,err.response)
  //         setIsAdmin(false);
  //         setActiveUser();
  //       }
  //     )
  //   };
  //   // eslint-disable-next-line
  // }, [localStorage.getItem('token')])

  // LOGOUT
  function logout() {
    localStorage.removeItem('token');
    setActiveUser();
    setIsAdmin(false)
    setLoginModaleIsActive(false)
  }

  // ACTIONS 
  const actions = {
    // LOGIN ACTION
    loginAction: (val) => loginAction(val)
      .then(
        res  => {
          localStorage.setItem('token', res.data.token);
          if(res.status === 200) {
            setLoginStatus(200)
          };
        }
      ).catch(
        err => {
          console.log(err.response)
          if(err.response.status === 403) {
            setLoginStatus(err.response.status)
          };
        }
      ),
    resetPasswordAction: (val) => resetPasswordAction(val)
      .then(
        res  => {
          console.log(res)
        }
      ).catch(
        err => {
          console.log(err.response)
        }
      ),
    postNewPassword:(obj) => postNewPassword(obj)
    .then(
      res  => {
        history.push('/')
        setLoginModaleIsActive(true)
      }
    ).catch(
      err => {
        console.log(err.response)
      }
    ),
    //LOGOUT
    logout: () => logout(),
  }

  const state = {...dataState , ...viewState , ...actions }
  // console.log(state)

  return <authContext.Provider value={state}>{props.children}</authContext.Provider>
}

export const authContextConsumer = authContext.Consumer;